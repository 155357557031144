import { Link } from "gatsby"
import React, { useMemo } from "react"
import { formatNumber } from "../../utils/number"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { fromUnixTime, formatDistanceToNowStrict, isAfter } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

import * as styles from "./AuctionCard.module.scss"

const AuctionCard = ({ data }: any) => {
  const image = useMemo(() => {
    if (data?.asset?.mainMedia) {
      if (data.asset?.mainMedia?.file?.contentType?.includes("video")) {
        return {
          type: "video",
          image: data.asset?.mainMedia?.file?.url,
          title: data?.asset?.mainMedia?.title,
        }
      } else {
        return {
          type: "image",
          image: getImage(data?.asset?.mainMedia),
          title: data?.asset?.mainMedia?.title,
        }
      }
    } else {
      if (data?.additionalMedia?.[0]?.file?.contentType?.includes("video")) {
        return {
          type: "video",
          image: data.additionalMedia?.[0]?.file?.url,
          title: data.additionalMedia?.[0]?.title,
        }
      } else {
        return {
          type: "image",
          image: getImage(data?.additionalMedia?.[0]),
          title: data?.additionalMedia?.[0].title,
        }
      }
    }
  }, [data?.additionalMedia, data?.asset?.mainMedia])

  const priceLabel = useMemo(() => {
    if (data.auction) {
      const price = `${formatNumber(data.auction.currentBidInUSD)} USD`

      const bids =
        data.auction.history?.length === 1
          ? "(1 Bid)"
          : `(${data.auction.history?.length} Bids)`

      return `${price} ${data.auction.history?.length ? bids : ""}`
    }
    return ""
  }, [data.auction])

  const ending = useMemo(() => {
    if (data.auction && data.auction.dateEnding) {
      const date = new Date()
      const dateTimezoned = utcToZonedTime(date, "EST")

      const endingDate = fromUnixTime(data.auction.dateEnding)
      const endingDateTimezoned = utcToZonedTime(endingDate, "EST")

      if (isAfter(endingDateTimezoned, dateTimezoned)) {
        return `${formatDistanceToNowStrict(endingDateTimezoned)} remaining`
      } else {
        return "Ended"
      }
    }
    return ""
  }, [data.auction])

  return (
    <div className={styles.card}>
      <Link
        to={`/artists/${data.asset?.artist?.slug || "unknown"}/${
          data?.asset?.slug || "untitled"
        }/${data.lotId}`}
        className={styles.cardLink}
      >
        <div className={styles.cardImg}>
          {image.type === "video" ? (
            <video width="100%" autoPlay>
              <source src={image.image} type="video/mp4" />
            </video>
          ) : (
            <GatsbyImage
              image={image.image}
              alt={image.title ? image.title : ""}
            />
          )}
        </div>
        <ul className={styles.cardList}>
          <li>{data.asset?.artist?.name}</li>
          <li>
            <i>
              {data.asset?.title?.length > 15
                ? `${data?.asset?.title?.substring(0, 15)}...`
                : data.asset?.title}
            </i>
          </li>
          <li>{priceLabel}</li>
          <li>{ending}</li>
        </ul>
      </Link>
    </div>
  )
}

export default AuctionCard
